import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import selections from "./selections.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import snackbar from "./snackbar.module";
import confirmation from "./confirmation.module";
import uploads from "./uploads.module";
import dashboard from "./dashboard.module";
import users from "./users.module";
import menus from "./menus.module";
import reports from "./reports.module";
import pages from "./pages.module";
import banners from "./banners.module";
import brands from "./brands.module";
import photos from "./photos.module";
import settings from "./settings.module";

Vue.use(Vuex);

export default new Vuex.Store({
    state:{
        appUrl: ''
    },
    modules: {
        auth,
        selections,
        htmlClass,
        config,
        uploads,
        breadcrumbs,
        snackbar,
        confirmation,
        dashboard,
        users,
        menus,
        pages,
        banners,
        reports,
        brands,
        photos,
        settings,
    }
});
