import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
    mode: "history",
    routes: [
        {
            path: "/",
            redirect: "/dashboard",
            component: () => import("@/view/layout/Layout"),

            children: [
                {
                    path: "/dashboard",
                    name: "dashboard",
                    component: () => import("@/view/pages/realstate/dashboard/Dashboard.vue")
                },
                {
                    path: "/content-management",
                    name: "content-management",
                    component: () =>
                        import("@/view/pages/realstate/Vuetify.vue"),
                    children: [
                        {
                            path: "menus",
                            name: "menus",
                            // meta: {
                            //     auth: true,
                            //     permission: "categories.view"
                            // },
                            component: () => import("@/view/pages/realstate/menus/Menus")
                        },
                        {
                            path: "pages",
                            name: "pages",
                            // meta: {
                            //     auth: true,
                            //     permission: "categories.view"
                            // },
                            component: () => import("@/view/pages/realstate/pages/Pages")
                        },
                        {
                            path: "banners",
                            name: "banners",
                            // meta: {
                            //     auth: true,
                            //     permission: "categories.view"
                            // },
                            component: () => import("@/view/pages/realstate/banners/Banners")
                        },
                        {
                            path: "sponsors",
                            name: "sponsors",
                            // meta: {
                            //     auth: true,
                            //     permission: "categories.view"
                            // },
                            component: () => import("@/view/pages/realstate/brands/Brands")
                        },
                        {
                            path: "board",
                            name: "board",
                            // meta: {
                            //     auth: true,
                            //     permission: "categories.view"
                            // },
                            component: () => import("@/view/pages/realstate/photos/Photos")
                        },
                        {
                            path: "news",
                            name: "news",
                            // meta: {
                            //     auth: true,
                            //     permission: "categories.view"
                            // },
                            component: () => import("@/view/pages/realstate/reports/Reports")
                        },
                    ]
                },
                {
                    path: "/users",
                    name: "users",
                    // meta: {
                    //     auth: true,
                    //     permission: "listings.view"
                    // },
                    component: () => import("@/view/pages/realstate/users/Users.vue")
                },
                {
                    path: "/settings",
                    name: "settings",
                    // meta: {
                    //     auth: true,
                    //     permission: "listings.view"
                    // },
                    component: () => import("@/view/pages/realstate/settings/Settings.vue")
                },
                {
                    path: "/inquiries",
                    name: "inquiries",
                    // meta: {
                    //     auth: true,
                    //     permission: "listings.view"
                    // },
                    component: () => import("@/view/pages/realstate/inquiries/Inquiries.vue")
                },
            ]
        },
        {
            path: "/error",
            name: "error",
            component: () => import("@/view/pages/error/Error.vue"),
            children: [
                {
                    path: "error-1",
                    name: "error-1",
                    component: () => import("@/view/pages/error/Error-1.vue")
                },
                {
                    path: "error-2",
                    name: "error-2",
                    component: () => import("@/view/pages/error/Error-2.vue")
                },
                {
                    path: "error-3",
                    name: "error-3",
                    component: () => import("@/view/pages/error/Error-3.vue")
                },
                {
                    path: "error-4",
                    name: "error-4",
                    component: () => import("@/view/pages/error/Error-4.vue")
                },
                {
                    path: "error-5",
                    name: "error-5",
                    component: () => import("@/view/pages/error/Error-5.vue")
                },
                {
                    path: "error-6",
                    name: "error-6",
                    component: () => import("@/view/pages/error/Error-6.vue")
                }
            ]
        },
        {
            path: "/",
            component: () => import("@/view/pages/auth/Auth"),
            children: [
                {
                    name: "login",
                    path: "/login",
                    component: () => import("@/view/pages/auth/Login")
                },
                {
                    name: "register",
                    path: "/register",
                    component: () => import("@/view/pages/auth/Register")
                },
                {
                    name: "forgot-password",
                    path: "/forgot-password",
                    component: () => import("@/view/pages/auth/ForgotPassword")
                },
                {
                    name: "reset-password",
                    path: "/reset-password/:id",
                    component: () => import("@/view/pages/auth/ResetPassword")
                }
            ]
        },
        {
            path: "*",
            redirect: "/404"
        },
        {
            // the 404 route, when none of the above matches
            path: "/404",
            name: "404",
            component: () => import("@/view/pages/error/Error-1.vue")
        }
    ]
});
