<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <div>
                    <template>
                        <v-card>
                            <v-dialog persistent v-model="dialog" max-width="900"
                                      transition="dialog-bottom-transition">
                                <template v-slot:activator="{ on }">
                                    <div class="d-flex">
                                        <v-btn
                                                color="primary"
                                                dark
                                                class="ml-auto ma-3"
                                                @click="refreshData"
                                        >
                                            <i style="color: #fff" class="flaticon2-refresh-1"></i>
                                        </v-btn>
                                        <v-btn
                                                color="primary" dark class="ma-3 ml-0" v-on="on">
                                            Add
                                            <v-icon class="ml-2" small>fas fa-plus-circle</v-icon>
                                        </v-btn>
                                    </div>
                                </template>
                                <v-card>
                                    <v-toolbar
                                            dark
                                            color="primary"
                                    >
                                        <v-btn
                                                icon
                                                dark
                                                @click="closeDialog"
                                        >
                                            <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                        <v-toolbar-title>
                                            <span v-if="editedItem.id">Edit "{{ editedItem.title }}"</span>
                                            <span v-else>Create Sponsor</span>
                                        </v-toolbar-title>
                                        <v-spacer></v-spacer>
                                        <v-toolbar-items>
                                            <v-btn
                                                    :disabled="!validationRules.valid"
                                                    dark
                                                    text
                                                    @click="saveItem(editedItem)"
                                            >
                                                Save
                                            </v-btn>
                                        </v-toolbar-items>
                                    </v-toolbar>
                                    <v-tabs v-model="activeTab" background-color="#3a4c6e" left dark>
                                        <v-tabs-slider></v-tabs-slider>
                                        <v-tab>Details</v-tab>
                                        <v-tab>Media</v-tab>

                                        <v-tab-item>
                                            <v-card>
                                                <v-card-text>
                                                    <v-form
                                                            ref="form"
                                                            v-model="validationRules.valid"
                                                            lazy-validation
                                                    >
                                                        <v-row>
                                                            <v-col cols="12" sm="6">
                                                                <v-select :items="spFilter"
                                                                          v-model="editedItem.type"
                                                                          item-value="value"
                                                                          item-text="title"
                                                                          :rules="validationRules.selectRules"
                                                                          label="Type*"
                                                                ></v-select>
                                                            </v-col>
                                                            <v-col cols="12" sm="6">
                                                                <v-select
                                                                        :items="[{value: '2023'},{value: '2024'}]"
                                                                        v-model="editedItem.year"
                                                                        item-value="value"
                                                                        item-text="value"
                                                                        :rules="validationRules.selectRules"
                                                                        label="Year*"
                                                                ></v-select>
                                                            </v-col>
                                                            <v-col cols="12" sm="6">
                                                                <v-text-field @keydown.space="preventLeadingSpace"
                                                                              v-model="editedItem.title"
                                                                              :counter="200"
                                                                              maxLength="200"
                                                                              :rules="validationRules.titleRules"
                                                                              label="Title*"
                                                                ></v-text-field>
                                                            </v-col>
                                                            <v-col cols="12" sm="6">
                                                                <v-text-field
                                                                        v-model="editedItem.sort_order"
                                                                        :counter="3"
                                                                        maxLength="3"
                                                                        @keypress="isNumber($event)"
                                                                        @keydown.space="preventLeadingSpace"
                                                                        @paste="onPaste($event)"
                                                                        label="Sort Order"
                                                                ></v-text-field>
                                                            </v-col>
                                                            <v-col cols="12" sm="6">
                                                                <v-text-field @keydown.space="preventLeadingSpace"
                                                                              v-model="editedItem.link"
                                                                              :counter="300"
                                                                              maxLength="300"
                                                                              label="Link"
                                                                ></v-text-field>
                                                            </v-col>

                                                            <!--                              <v-col cols="12" sm="12" class="mb-10 pb-10">-->
                                                            <!--                                <label>Description</label>-->
                                                            <!--                                <quill-editor-->
                                                            <!--                                    style="height: 300px"-->
                                                            <!--                                    v-model="editedItem.description"-->
                                                            <!--                                />-->
                                                            <!--                              </v-col>-->
                                                        </v-row>
                                                    </v-form>
                                                </v-card-text>
                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn color="blue darken-1" text @click="closeDialog"
                                                    >Cancel
                                                    </v-btn
                                                    >
                                                    <v-btn
                                                            :disabled="!validationRules.valid"
                                                            color="blue darken-1"
                                                            text
                                                            @click="saveItem(editedItem)"
                                                    >
                                                        Save
                                                    </v-btn>
                                                </v-card-actions>
                                                <v-col cols="12">
                                                    <div
                                                            v-if="errors != undefined && errors.length >= 0"
                                                            role="alert"
                                                            v-bind:class="{ show: errors.length }"
                                                            class="alert fade alert-danger"
                                                    >
                                                        <div
                                                                class="alert-text"
                                                                v-for="(error, i) in errors"
                                                                :key="i"
                                                        >
                                                            {{ error }}
                                                        </div>
                                                    </div>
                                                </v-col>
                                            </v-card>
                                        </v-tab-item>
                                        <v-tab-item>
                                            <v-tabs
                                                    background-color="primary" left dark>
                                                <v-tabs-slider></v-tabs-slider>
                                                <v-tab>Brand Image</v-tab>

                                                <v-tab-item>
                                                    <ModuleMedia
                                                            moduleType="main"
                                                            moduleOf="brands"
                                                            moduleTitle="Thumbnail Image"
                                                            acceptedFiles="image/jpeg, image/png"
                                                            :moduleMultiple="false"
                                                            :deleteModule="false"
                                                            :moduleFilesOld="editedItem.image_public_url ? [editedItem] : []"
                                                    ></ModuleMedia>
                                                </v-tab-item>
                                            </v-tabs>
                                        </v-tab-item>
                                    </v-tabs>
                                </v-card>
                            </v-dialog>
                            <hr style="margin:0"/>
                            <div class="row">
                                <div class="col-md-12">
                                    <v-card-title>
                                        Sponsors ({{ totalRecords }})
                                        <v-spacer></v-spacer>
                                        <v-text-field
                                                @keydown.enter="searchQueryData"
                                                append-icon="search"
                                                label="Search"
                                                single-line
                                                hide-details
                                        ></v-text-field>
                                    </v-card-title>
                                </div>
                            </div>
                            <v-skeleton-loader
                                    :loading="loading"
                                    v-if="isLoaded"
                                    type="table"
                            >
                            </v-skeleton-loader>
                            <v-data-table
                                    :headers="headers"
                                    :items="items"
                                    :options.sync="pagination"
                                    :server-items-length="totalRecords"
                                    :footer-props="{
                  itemsPerPageOptions: rowsPerPageItems,
                  showFirstLastPage: true
                }"
                                    :loading="loading"
                                    v-show="!isLoaded"
                                    mobile-breakpoint="800"
                                    class="elevation-0"
                            >
                                <template v-slot:item.title="{ item }">
                                    <div class="text-truncate">
                                        <p v-html="item.title"></p>
                                    </div>
                                </template>
                                <template v-slot:item.status="{ item }">
                                    <v-switch
                                            v-model="item.status"
                                            v-bind:color="item.status === 1 ? 'success': ''"
                                            item-value="value"
                                            @change="updateStatus($event,item.id,'status')"
                                    ></v-switch>
                                </template>
                                <template v-slot:item.created_at="{ item }">
                                    <div class="text-truncate">
                                        {{ formatDateAndTime(item.created_at) }}
                                    </div>
                                </template>
                                <template v-slot:item.updated_at="{ item }">
                                    <div class="text-truncate">
                                        {{ formatDateAndTime(item.updated_at) }}
                                    </div>
                                </template>
                                <template v-slot:item.actions="{ item }">
                                    <div class="text-truncate">
                                        <v-btn
                                                color="primary"
                                                @click="showEditDialog(item)"
                                                dark
                                                class="mr-2"
                                                small
                                        >
                                            <v-icon dark small>fas fa-edit</v-icon>
                                        </v-btn>
                                        <v-btn
                                                color="red"
                                                small
                                                dark
                                                class="mr-2"
                                                @click="deleteItem(item)">
                                            <v-icon dark small>fas fa-trash</v-icon>
                                        </v-btn>
                                    </div>
                                </template>
                            </v-data-table>
                            <!-- this dialog is used for both create and update -->
                        </v-card>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ModuleMedia from "@/view/pages/realstate/media/ModuleMedia";
import ModuleUploadedFiles from "@/view/pages/realstate/media/ModuleUploadedFiles";
import {bus} from "@/main";
import {
    BRANDS_LIST,
    CREATE_BRAND,
    UPDATE_BRAND,
    CLEAR_BRAND_ERRORS,
    GET_BRAND_DETAILS, DELETE_BRAND, UPDATE_BRAND_STATUS
} from "@/core/services/store/brands.module";
import {SET_CONFIRMATION} from "@/core/services/store/confirmation.module";
import {MENUS_SIMPLE_LIST} from "@/core/services/store/menus.module";

export default {
    name: "brands",
    components: {
        ModuleMedia,
        ModuleUploadedFiles
    },
    data() {
        return {
            headers: [
                {
                    text: "Type",
                    value: "type",
                    name: "type",
                    filterable: true,
                    sortable: false
                },
                {
                    text: "Year",
                    value: "year",
                    name: "year",
                    filterable: false,
                    sortable: false
                },
                {
                    text: "Title",
                    value: "title",
                    name: "title",
                    filterable: true,
                    sortable: true
                },
                {
                    text: "Sort Order",
                    value: "sort_order",
                    name: "sort_order",
                    filterable: false,
                    sortable: false
                },
                {
                    text: "Status",
                    value: "status",
                    name: "status",
                    filterable: false,
                    sortable: false
                },
                {
                    text: "Created",
                    value: "created_at",
                    name: "created_at",
                    filterable: false,
                    sortable: true
                },
                {
                    text: "Updated",
                    value: "updated_at",
                    name: "updated_at",
                    filterable: false,
                    sortable: true
                },
                {
                    text: "Actions",
                    value: "actions",
                    name: "actions",
                    filterable: false,
                    sortable: false
                }
            ],
            isLoaded: true,
            draw: 1,
            searchQuery: "",
            loading: true,
            pagination: {
                page: 1,
                rowsPerPage: 25,
                totalItems: 0,
                sortDesc: "desc"
            },
            totalRecords: 0,
            rowsPerPageItems: [25, 50, 100, 150, 200],
            headerindex: "",
            columns: [],
            validationRules: {
                valid: true,
                titleRules: [
                    v => !!v || "This field is required",
                    v => (v && v.length >= 3) || "Must be greater than or equal to 3 characters",
                    v => (v && v.length <= 200) || "Must be less than or equal to 200 characters"
                ],
                linkRules: [
                    v => !!v || "This field is required",
                    v => (v && v.length >= 3) || "Must be greater than or equal to 3 characters",
                    v => (v && v.length <= 500) || "Must be less than or equal to 500 characters"
                ],
                oLRules: [
                    v => {
                        if (!isNaN(parseFloat(v)) && v >= 0 && v <= 1000) return true;
                        return 'Must be in between 0 and 1000';
                    },
                ],
                metaTitleRules: [
                    v => (v || '').length <= 255 || 'Must be less than or equal to 255 characters'
                ],
                metaDesRules: [
                    v => (v || '').length <= 500 || 'Must be less than or equal to 500 characters'
                ],
                selectRules: [
                    (v) => {
                        if (!v || v.length < 1)
                            return 'Select an option';
                        else return true;
                    }
                ],
                multipleRules: [
                    v => {
                        if (!v || v.length < 1) return "Select at least one option";
                        else return true;
                    }
                ]
            },
            items: [],
            dialog: false,
            editedItem: {},
        };
    },
    watch: {
        params: {
            handler() {
                this.loadData().then(response => {
                    this.items = response.items;
                    this.totalRecords = response.total;
                });
            },
            deep: true
        },
        dialog(val) {
            !val && this.$store.dispatch(CLEAR_BRAND_ERRORS);
            if (!val) {
                let that = this;
                setTimeout(function () {
                    that.editedItem.description = '';
                }, 500)
            }
            if (!val) this.editedItem = {};
            if (!val) bus.$emit("moduleFilesDeletedByModal");
            !val && this.$refs.form.resetValidation();
        }
    },
    mounted() {
        for (var i = 0; i < this.headers.length; i++) {
            this.columns.push(
                "&columns[" +
                i +
                "][data]=" +
                this.headers[i].value +
                "&columns[" +
                i +
                "][name]=" +
                this.headers[i].name +
                "&columns[" +
                i +
                "][searchable]=" +
                this.headers[i].filterable +
                "&columns[" +
                i +
                "][orderable]=" +
                this.headers[i].sortable +
                "&columns[" +
                i +
                "][search][value]=" +
                "&columns[" +
                i +
                "][search][regex]=" +
                false
            );
        }
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Content Management", route: "brands"},
            {title: "Brands"}
        ]);
        if (this.$route.query.hasOwnProperty("addModule")) {
            this.dialog = true;
        }
    },
    methods: {
        refreshData() {
            this.params.sortBy = [];
            this.params.query = "";
            this.getByTypeValue = 'all';
            this.params.sortDesc = "desc";
            this.loadData().then(response => {
                this.items = response.items;
                this.totalRecords = response.total;
            });
        },
        searchQueryData: function (e) {
            if (e.keyCode === 13) {
                this.searchQuery = e.target.value;
            }
        },
        getHeaderIndex(name) {
            let that = this;
            this.headers.filter(function (ele, i) {
                if (name == ele.name) {
                    that.headerindex = i;
                }
            });
        },
        loadData() {
            this.loading = true;
            this.isLoaded = true;
            return new Promise(resolve => {
                let params = this.params;
                params.length = params.itemsPerPage; //set how many records to fetch per brand
                params.start =
                    params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
                params.draw = this.draw;
                let query = Object.keys(params)
                    .map(key => {
                        return (
                            encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
                        );
                    })
                    .join("&");
                this.getHeaderIndex(params.sortBy[0]);
                query +=
                    "&search[value]=" +
                    params.query +
                    "&search[regex]=" +
                    false +
                    "&order[0][column]=" +
                    (params.sortBy.length === 0 ? 5 : this.headerindex) +
                    "&order[0][dir]=" +
                    (params.sortDesc[0] ? "desc" : "asc");
                query += "&type=" + this.getByTypeValue;
                this.$store
                    .dispatch(BRANDS_LIST, {q: query, columns: this.columns})
                    .then(res => {
                        this.draw++;
                        let items = res.records.data;
                        let total = res.records.recordsFiltered;
                        this.loading = !this.loading;
                        this.isLoaded = !this.isLoaded;
                        resolve({
                            items,
                            total
                        });
                    });
            });
        },
        reset() {
            this.$refs.form.reset();
        },

        resetValidation() {
            this.$refs.form.resetValidation();
        },
        showEditDialog(item) {
            this.$store.dispatch(GET_BRAND_DETAILS, item.id).then(data => {
                this.editedItem = data.records || {};
                this.dialog = !this.dialog;
            });
        },
        closeDialog() {
            this.dialog = !this.dialog;
        },
        saveItem(item) {
            if (this.$refs.form.validate()) {
                let method = CREATE_BRAND;
                let id = item.id;
                this.$set(item, "image", this.$store.state.uploads.uploadedStateFiles);
                let data = {
                    data: item
                };
                if (id) {
                    method = UPDATE_BRAND;
                }
                this.$store.dispatch(CLEAR_BRAND_ERRORS);
                this.$store.dispatch(method, {slug: id, data: data.data}).then(() => {
                    this.loadData().then(response => {
                        this.items = response.items;
                        this.totalRecords = response.total;
                    });
                    this.editedItem = {};
                    this.dialog = !this.dialog;
                    bus.$emit("moduleFilesDeletedByModal");
                    if (this.$route.query.hasOwnProperty("addModule")) {
                        window.close();
                    }
                });
            }
        },
        deleteItem(item) {
            let that = this;
            this.$store.dispatch(SET_CONFIRMATION, {
                title: "Are you sure?",
                msg: "You want to delete? All relational data will be lost!",
                clr: "red",
                callback: function () {
                    this.$store
                        .dispatch(DELETE_BRAND, {
                            'id': item.id,
                        })
                        .then(() => {
                            that.loadData().then(response => {
                                that.items = response.items;
                                that.totalRecords = response.total;
                            });
                        });
                }
            });
        },
        updateStatus(status, cId, column) {
            this.$store.dispatch(CLEAR_BRAND_ERRORS);
            let data = {
                status: status,
                column: column
            };
            this.$store.dispatch(UPDATE_BRAND_STATUS, {'slug': cId, data: data}).then(() => {
                this.loadData().then(response => {
                    this.items = response.items;
                    this.totalRecords = response.total;
                });
            })
        }
    },
    computed: {
        params() {
            return {
                ...this.pagination,
                query: this.searchQuery
            };
        },
        ...mapState({
            errors: state => state.brands.errors
        })
    }
};
</script>